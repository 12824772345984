import React, { useEffect, useState } from 'react';

import Calculation from './calculation';
import Instructions from './instructions';
import Tree from './tree';

export default function Game(props) {

  const [values, setValues] = useState({});
  const [collectedApples, setCollectedApples] = useState(0);
  // helps determine whether calculation elements can be reset
  const [reset, setReset] = useState(false);

  const generateEquationValues = () => {
    let min = 1;
    let max = 9;
    let left = Math.floor(Math.random() * (max - min) + min);
    // using left in the right generation ensures right is always greater
    let right = Math.floor(Math.random() * (max - left) + left + min);
    setValues({left: left, right: right});
  };

  const processSolved = () => {
    generateEquationValues();
    setCollectedApples(0);
  }

  useEffect(() => {
    // the [] dependency guarantees this will only trigger once on load
    generateEquationValues();
  }, []);

  return (
    <div className="game">
      <Tree
        collectedApples={collectedApples}
        addCollectedApple={() => setCollectedApples(collectedApples + 1)}
      />
      <Calculation
        collectedApples={collectedApples}
        left={values.left}
        right={values.right}
        processSolved={processSolved}
        setCollectedApples={setCollectedApples}
      />
      <Instructions />
    </div>
  );
}
