const Apple = ({allApples, id, img, x, removeApple, collectApple}) => {
  const handleRemove = () => {
    const filteredApples = allApples.filter(apple => apple.id !== id);
    removeApple(filteredApples);
    collectApple();
  };

  // separate fall and spin animations; combining them caused undesired behavior related to div/img centering
  return (
    <div className='appleFall'>
      <img src={img} className='appleSpin' style={{position: 'absolute', left: x}} alt='apple' onClick={handleRemove} />
    </div>
  );
};

export default Apple;
