import { useState } from 'react';

import tree from '../static/img/apple-tree.png';
import Apple from './apple'
import applered from '../static/img/apple-red.png';
import applegreen from '../static/img/apple-green.png';


export default function Tree(props) {
  // TODO: convert to react-transition-group vs pure css for more control
  const baseClassNames = "treeImage";
  const shakeClassNames = baseClassNames + " shake";

  const [classNames, updateClassName] = useState(baseClassNames);
  const [apples, setApples] = useState([]);

  function shakeTree() {
    spawnApple()
    updateClassName(shakeClassNames);
    // resets shake animation class
    setTimeout(() => updateClassName(baseClassNames), 500);
  }

  function spawnApple() {
    // TODO: derive these min/max values from parent tree width/positioning
    let min = 0;
    let max = 300;
    let appleImages = [applered, applegreen];
    let apple = {
      id: new Date().getTime(),
      // randomly select an apple resource
      img: appleImages[Math.floor(Math.random() * appleImages.length)],
      // randomly select an x position
      x: Math.floor(Math.random() * (max - min) + min),
    };
    setApples(apples => [...apples, apple]);
  }

  function collectApple() {
    const maxApples = 9;
    if (props.collectedApples >= maxApples) {
      return;
    }
    props.addCollectedApple();
  }

  const fallenApplesRender = apples.map( apple => (
    <Apple
      allApples={apples}
      id={apple.id}
      img={apple.img}
      x={apple.x}
      removeApple={setApples}
      collectApple={collectApple}
      key={apple.id}
    />
  ));

  return (
    <div className="tree">
      <img src={tree} className={classNames} alt="tree" onClick={() => shakeTree()} />
      <div className="apples">{fallenApplesRender}</div>
    </div>
  );
}
