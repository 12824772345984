const Instructions = () => {
  return (
    <div className='instructions'>
      <p>Click the tree to shake apples free.</p>
      <p>Collect apples to complete the equation.</p>
      <p>Click the collected apples number to remove an apple.</p>
      <p>Click the 'check' button to check your work!</p>
    </div>
  );
};

export default Instructions;
